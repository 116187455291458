import React from 'react'
import { Link } from "gatsby"
import { Container, Row, Col, Button } from 'reactstrap';
import Header from './Header';
import Footer from './Footer'
import Helmet from 'react-helmet'
import styled from 'styled-components';

const BlogTitle = styled.h1`
  padding-bottom: 25px;
`
const BlogContainer = styled.div`
  max-width: 740px;
  padding: 0 20px 75px 20px;
  margin: 0 auto 25px auto;
  border-bottom: 2px solid #7232E8;
  font-family: 'Georgia, Cambria, "Times New Roman", Times, serif';
  font-size: 21px;
  line-height: 1.58;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -.003em;
  color: rgba(0,0,0,.84);
`
const BlogBottomContainer = styled.div`
  max-width: 740px;
  margin: 0 auto;
  padding: 0 20px;
  a {
    color: #7232E8;
  }
`
const BlogContent = styled.div`
  h1 {
    margin: 1.8em 0;
    line-height: 1.33;
    font-size: 2em;
    &:after {
      content: "";display: block;position: relative;top: .33em;border-bottom: 1px solid hsla(0,0%,50%,.2);
    }
  }
  h2 {
    margin: 1.8em 0; line-height: 1.33; font-size: 1.5em;
    &:after {
      content: "";display: block;position: relative;top: .33em;border-bottom: 1px solid hsla(0,0%,50%,.2);
    }
  }
  table {
    background-color: transparent; border-collapse: collapse; border-spacing: 0; display: table; border-color: grey;
  }
  th {
    border-right: 1px solid #dcdcdc; padding: 8px 12px; text-align: center;
    &:last-child {
      border-right: 0;
    }
  }
  td {
    border-top: 1px solid #dcdcdc; border-right: 1px solid #dcdcdc; padding: 8px 12px;
    &:last-child {
      border-right: 0;
    }
  }
  a {
    color: #7232E8;
  }
`
const BlogDate = styled.div`
  color: rgba(0,0,0,.54);
  font-size: 14px;
  text-align: right;
  margin-bottom: 15px;
`

export default ({data}) => {
  const post = data.markdownRemark
  return (
  <div>
    <Helmet
      title={post.frontmatter.title + " | Bishop Software"}
      meta={[
        { name: 'description', content: post.frontmatter.description },
        { name: 'keywords', content: post.frontmatter.keywords },
      ]}
    />

    <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #14134e 0, #512d5a 56%, #843b61 81%, #c16858 100%)', color: '#fafafa'}}>
      <Header dropdownGradient='linear-gradient(180deg, #d15e95 0, #c45d50 100%)' />
    </section>

    <section style={{padding: '70px 0'}}>
      <BlogContainer>
        <BlogDate>{post.frontmatter.date}</BlogDate>
        <BlogTitle>{post.frontmatter.title}</BlogTitle>
        <BlogContent dangerouslySetInnerHTML={{ __html: post.html }} />
      </BlogContainer>
      <BlogBottomContainer>
        <Row>
          <Col>
            <Link to="/blog"><i className="fas fa-arrow-left" /> Back to the blog</Link>
          </Col>
        </Row>
      </BlogBottomContainer>
    </section>

    <Footer />
  </div>
  )
}

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        image {
          publicURL
        }
        description
        keywords
      }
    }
  }
`